<template>
  <Modal v-model="showProductHistory" title="变更记录" width="1000" footer-hide>
    <Table size="small" stripe :data="list" :loading="tableLoading" :columns="tableColumns"></Table>
  </Modal>
</template>

<script>
import { getHistory } from '@/api/product/cloudinventory'

export default {
  data () {
    return {
      showProductHistory: false,
      tableLoading: false,
      publishStatusArray: [],
      list: [],
      tableColumns: [
        {
          title: '变更状态',
          render: (h, params) => {
            let changeStatusName = ''
            const status = this.publishStatusArray.find(x => x.value === params.row.status.toString())
            if (status) {
              changeStatusName = status.name
            }

            return h('span', changeStatusName)
          }
        },
        { title: '变更内容', key: 'launchName' },
        { title: '操作人', key: 'userName' },
        { title: '操作时间', key: 'updateTime' }
      ]
    }
  },
  methods: {
    showModal (inventoryId, statusArray) {
      this.showProductHistory = true
      this.publishStatusArray = statusArray
      this.initData(inventoryId)
    },
    initData (inventoryId) {
      this.tableLoading = true
      const queryModel = {
        inventoryId: inventoryId
      }

      getHistory(queryModel).then(res => {
        this.list = res
        this.tableLoading = false
      })
    }
  }
}
</script>
